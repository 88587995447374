







































































































































import Vue from 'vue'
import Component from 'vue-class-component'
import ListView from '@/components/list/ListView.vue'
import PageTab from '@/components/ui/PageTab.vue'
import { vxm } from '@/store'

@Component({
  components: {
    ListView,
    PageTab,
  },
})
export default class List extends Vue {
  $refs: Vue['$refs'] & {
    email: HTMLInputElement
    correctNameForm: {
      validate: any
      reset: any
    }
    aliasForm: {
      validate: any
      reset: any
    }
  }

  private listViewKey = 0
  private loadingData = false
  private loading = false

  private fetchedTypes = []
  private type = null
  private namesToAdd = null

  private aliasesToAdd = ''
  private aliasCorrectNameId = null
  private aliasCorrectName = ''

  private aliasDialog = false
  private correctNameDialog = false

  private headers = []
  private rowActions = []
  private topActions = []

  private correctNameDetailsDialog = false
  private correctNameDetailsData = null

  created() {
    this.setHeaders()
    this.setRowActions()
    this.fetchCorrectNames()
    this.setTopActions()
  }

  private setTopActions() {
    this.topActions.push({
      id: 'new',
      template: 'new',
      label: 'c:alias:New Correct Name',
      click: () => {
        this.correctNameDialog = true
      },
    })
  }

  private fetchCorrectNames() {
    this.$axios.get(this.url).then((cn) => {
      this.fetchedTypes = Object.keys(cn.data.types).map((id) => ({
        id,
        name: cn.data.types[id],
      }))
    })
  }

  private submitCorrectNameForm(): void {
    if (this.$refs.correctNameForm.validate()) {
      this.loading = true
      const correctNameData = {
        name: this.namesToAdd,
        type: this.type,
      }

      this.$axios
        .post(this.url, correctNameData)
        .then(() => {
          this.listViewKey += 1
          this.correctNameDialog = false
          this.fetchCorrectNames()
          this.$refs.correctNameForm.reset()
        })
        .catch((err) => {
          vxm.alert.onAxiosError(err, 'Error loading correct names')
        })
        .finally(() => {
          this.loading = false
        })
    }
  }

  private submitAliasForm(): void {
    if (this.$refs.aliasForm.validate()) {
      this.loading = true
      const aliasData = {
        alias: this.aliasesToAdd,
        correctNameId: this.aliasCorrectNameId,
      }

      this.$axios
        .post('/v4/site/aliases', aliasData)
        .then(() => {
          this.listViewKey += 1
          this.aliasDialog = false
          this.correctNameDetailsDialog = false
          this.fetchCorrectNames()
          this.$refs.aliasForm.reset()
        })
        .catch((err) => {
          vxm.alert.onAxiosError(err, 'Error loading aliases')
        })
        .finally(() => {
          this.loading = false
        })
    }
  }

  private newAliasDialog(correctName): void {
    this.aliasCorrectNameId = correctName.id
    this.aliasCorrectName = correctName.name
    this.aliasDialog = true
  }

  private newCorrectNameDialog(): void {
    this.correctNameDialog = true
  }

  private get url() {
    return '/v4/site/aliases/correct'
  }

  private setHeaders() {
    this.headers = [
      {
        text: 'c:alias:Correct Name',
        value: 'name',
        filter: { disable: false },
      },
      {
        text: 'c:alias:Type',
        value: 'typeName',
        filter: { disable: true },
        sortable: false,
      },
      {
        text: 'Actions',
        value: 'actions',
      },
    ]
  }

  private setRowActions() {
    this.rowActions = [
      {
        id: 'show',
        click: (_, item) => {
          this.correctNameDetailsDialog = true
          this.correctNameDetailsData = item
          this.aliasCorrectNameId = item.id
          this.aliasCorrectName = item.name
        },
      },
      {
        id: 'delete',
      },
    ]
  }
}
